
    .In {
        padding: 5px;
        margin: auto;
        width: 5px;
        background-color: green;
        position:relative;
        right: 20%;
        margin-top: 5%;
    }

    .Out {
        padding: 5px;
        margin: auto;
        width: 5px;
        align-items: center;
        background-color: crimson;
    }

    .cellWithImg {
        display: flex;
        align-items: center;

        .cellImg {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
    }
